<template>
    <div>
        <h2 class="mb-1">Call Data Report</h2>
        
        <div style="display: flex; flex-wrap: wrap; align-items: center;" class="mb-2">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
        
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>
            
            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            
            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
        
            <b-form-group
                label="Borough"
                v-if="region === 'NYC'"
            >
                <v-select
                    v-model="borough"
                    placeholder="Any Borough"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsBorough"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
        
            <b-form-group
                label="Language"
            >
                <v-select
                    v-model="language"
                    placeholder="Language"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsLanguage"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
        
            <b-form-group
                label="Warm Transfer"
            >
                <v-select
                    v-model="warmTransfer"
                    placeholder="Warm Transfer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsWarmTransfer"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2"
                />
            </b-form-group>
            <div>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="mb-1 mt-2 p-75"
                    @click="activeFilterFunction"
                >
                    <feather-icon
                        icon="Xicon"
                        class="mr-50"
                    />
                    <span class="align-middle">Reset filters</span>
                </b-button>
            </div>
        </div>

        <b-card>
            <h5>Engagement Sessions</h5>
            <hr>
            <b-row>
                <!-- Total Individuals -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ engagementFormCompleted && engagementFormCompleted !== 'NaN' ? engagementFormCompleted.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Total Individuals</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Scheduled For Initial Appointment -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ counterInitialAppointmentDate && counterInitialAppointmentDate !== 'NaN' ? counterInitialAppointmentDate.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Scheduled For Initial Appointment</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <!-- Total Eligible Callers -->
                <b-col
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ counterTotalEligibleCallers && counterTotalEligibleCallers !== 'NaN' ? counterTotalEligibleCallers.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Total Eligible Callers</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Attended Face To Face Session -->
                <b-col
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ counterAttendedFaceToFaceSession && counterAttendedFaceToFaceSession !== 'NaN' ? counterAttendedFaceToFaceSession.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Attended Face To Face Session</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Admitted To Treatment -->
                <b-col
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ counterAdmittedToTreatment && counterAdmittedToTreatment !== 'NaN' ? counterAdmittedToTreatment.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Admitted To Treatment</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>

        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-call-data.csv"
            :style="readyToExport ? 'background: #94221e; color: #fff; cursor: pointer; opacity: 1;' : 'background: #94221e; color: #fff; cursor: no-drop; opacity: 0.5;'"
            delimiter=";"
            v-if="isDataSpecialist"
        >
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </download-csv>

        <b-card>
            <h4>Calls Received By Referral Source</h4>
            <b-table
                :items="arrayTableContent"
                :fields="fieldsTable"
                responsive="sm"
                thead-class="hidden_header"
                :busy="isBusyCalls"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">Loading...</strong>
                    </div>
                </template>
                <!-- Column: Quantity -->
                <template #cell(quantity)="data">
                    <h4>{{ data.item.name === 'Existing or past CFE client' ? countExistingorpastCFEclient : data.item.name === 'Family Member / Self' ? countFamilymemberSelf : data.item.name === 'GA/ GamAnon' ? countGAGamAnon : data.item.name === 'HOPEline' ? countHOPEline : data.item.name === 'Warm Transfer' ? counterWarmTransfer : data.item.name === 'Internet Search' ? countInternetSearch : data.item.name === 'Legal System' ? countLegalSystem : data.item.name === 'Other (please describe)' ? countOtherpleasedescribe : data.item.name === 'Other service provider' ? countOtherserviceprovider : data.item.name === 'Friends or Other Individual' ? countFriendsorOtherIndividual : data.item.name === 'Problem Gambling Self-Help Group Meetings' ? countProblemGamblingSelfHelpGroupMeetings : data.item.name === 'Substance Use Self-Help Group Meetings' ? countSubstanceUseSelfHelpGroupMeetings : data.item.name === 'Financial Counseling' ? countFinancialCounseling : data.item.name === 'Crisis Services' ? countCrisisServices : data.item.name === 'Problem Gambling Treatment Services' ? countProblemGamblingTreatmentServices : data.item.name === 'Substance Use Treatment Services' ? countSubstanceUseTreatmentServices : data.item.name === 'Recovery Support Services' ? countRecoverySupportServices : data.item.quantity }}</h4>
                </template>
            </b-table>
        </b-card>

    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            isDataSpecialist: false,
            readyToExport: false,

            // filters
            startDate: null,
            endDate: null,
            region: '',
            borough: '',
            language: '',
            progress: '',
            insurance: '',
            warmTransfer: '',

            // data report
            engagementFormCompleted: 0,
            counterInformationKitAccepted: 0,
            counterInitialAppointmentDate: 0,
            counterTotalEligibleCallers: 0,
            counterAttendedFaceToFaceSession: 0,
            counterAdmittedToTreatment: 0,
            countExistingorpastCFEclient: 0,
            countFamilymemberSelf: 0,
            countGAGamAnon: 0,
            countHOPEline: 0,
            countInternetSearch: 0,
            countLegalSystem: 0,
            countOtherserviceprovider: 0,
            countOtherpleasedescribe: 0,
            counterWarmTransfer: 0,
            countFriendsorOtherIndividual: 0,
            countProblemGamblingSelfHelpGroupMeetings: 0,
            countSubstanceUseSelfHelpGroupMeetings: 0,
            countFinancialCounseling: 0,
            countCrisisServices: 0,
            countProblemGamblingTreatmentServices: 0,
            countSubstanceUseTreatmentServices: 0,
            countRecoverySupportServices: 0,

            // options
            optionsRegion: [],
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],
            // optionsRegion: [
            //     'Bronx',
            //     'Brooklyn',
            //     'Central',
            //     'Finger Lakes',
            //     'Long Island',
            //     'Manhattan',
            //     'Mid-Hudson',
            //     'Northeast',
            //     'Queens',
            //     'Staten Island',
            //     'Western'
            // ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],
            optionsWarmTransfer: ['Yes', 'No'],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'name', sortable: false },
                { key: 'quantity', sortable: false },
            ],
            arrayTableContent: [
                { 'name': 'Existing or past CFE client', quantity: 0 },
                { 'name': 'Family Member / Self', quantity: 0 },
                { 'name': 'GA/ GamAnon', quantity: 0 },
                { 'name': 'HOPEline', quantity: 0 },
                { 'name': 'Warm Transfer', quantity: 0 },
                { 'name': 'Internet Search', quantity: 0 },
                { 'name': 'Legal System', quantity: 0 },
                { 'name': 'Friends or Other Individual', quantity: 0 },
                { 'name': 'Problem Gambling Self-Help Group Meetings', quantity: 0 },
                { 'name': 'Substance Use Self-Help Group Meetings', quantity: 0 },
                { 'name': 'Financial Counseling', quantity: 0 },
                { 'name': 'Crisis Services', quantity: 0 },
                { 'name': 'Problem Gambling Treatment Services', quantity: 0 },
                { 'name': 'Substance Use Treatment Services', quantity: 0 },
                { 'name': 'Recovery Support Services', quantity: 0 },
                { 'name': 'Other (please describe)', quantity: 0 },
                { 'name': 'Other service provider', quantity: 0 },
            ],

            // json to export csv
            json_data: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
            isBusyCalls: true,
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        DatePicker,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsCallData']),
        ...mapState(['searchReportsCallDataStartDateState','searchReportsCallDataEndDateState','searchReportsCallDataRegionState','searchReportsCallDataProgressState','searchReportsCallDataLanguageState','searchReportsCallDataInsuranceState','searchReportsCallDataWarmTransferState','searchReportsCallDataProgramState'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        clientActivityStatus(val) {
            this.searchClientActivityStatus(val)
        },
        startDate(val) {
            this.searchReportsCallDataStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            this.rangeDinamic ? this.rangeDinamic = false : ''
            // this.selectedRangeDate = ''
        },
        endDate(val) {
            this.searchReportsCallDataEndDate(val)
        },
        region(val) {
            // val !== 'NYC' ? this.searchReportsCallDataRegion(val) : ''
            this.searchReportsCallDataRegion(val)
        },
        borough(val) {
            this.searchReportsCallDataRegion(val)
        },
        progress(val) {
            this.searchReportsCallDataProgress(val)
        },
        language(val) {
            this.searchReportsCallDataLanguage(val)
        },
        insurance(val) {
            this.searchReportsCallDataInsurance(val)
        },
        warmTransfer(val) {
            this.searchReportsCallDataWarmTransfer(val)
        },
        clinicianProgram(val) {
            this.searchReportsCallDataProgram(val)
        },
        arrayFiltradoReportsCallData(val) {
            this.isBusy = true
            this.engagementFormCompleted = 0
            this.counterInformationKitAccepted = 0
            this.counterInitialAppointmentDate = 0
            this.counterTotalEligibleCallers = 0
            this.counterAttendedFaceToFaceSession = 0
            this.counterAdmittedToTreatment = 0
            this.countExistingorpastCFEclient = 0
            this.countFamilymemberSelf = 0
            this.countGAGamAnon = 0
            this.countHOPEline = 0
            this.countInternetSearch = 0
            this.countLegalSystem = 0
            this.countOtherserviceprovider = 0
            this.countOtherpleasedescribe = 0
            this.counterWarmTransfer = 0
            this.countFriendsorOtherIndividual = 0
            this.countProblemGamblingSelfHelpGroupMeetings = 0
            this.countSubstanceUseSelfHelpGroupMeetings = 0
            this.countFinancialCounseling = 0
            this.countCrisisServices = 0
            this.countProblemGamblingTreatmentServices = 0
            this.countSubstanceUseTreatmentServices = 0
            this.countRecoverySupportServices = 0
            var arrayExport = []
            this.readyToExport = false
            // var x = 0
            // var y = 0
            val.arregloFiltrado.forEach(dataClientActivity => {
                if(dataClientActivity.includeByAdmission) {
                    if((dataClientActivity.statusLogical === 'Admitted' || dataClientActivity.statusLogical === 'Discharged') && dataClientActivity.admissionDateFormat) {
                        this.counterAdmittedToTreatment += 1
                    }
                }
                
                if(dataClientActivity.includeByContact) {
                    if(dataClientActivity.statusLogical === 'Closed - Received information' || dataClientActivity.statusLogical === 'Closed – Received information') {
                        dataClientActivity.statusLogical = 'Closed – Received information'
                    }
                    // console.log(dataClientActivity.warmTransfer)
                    // Total Individuals
                    // !dataClientActivity.dateIn && !dataClientActivity.dateAd ? 
                    this.engagementFormCompleted += 1 
                    // : ''
                    
                    // Received KTO Kit
                    if(dataClientActivity.informationKitAccepted === 'Yes') {
                        this.counterInformationKitAccepted += 1
                    }

                    // Scheduled For Initial Appointment
                    // if(val.totalInitialAppointment >= 1) {
                    //     this.counterInitialAppointmentDate = val.totalInitialAppointment
                    // } else {
                        if(dataClientActivity.initialAppointmentDate && dataClientActivity.initialAppointmentDate !== ' 00:00') {
                            // if(this.startDate || this.endDate || this.region || this.language || this.warmTransfer) {
                                // dataClientActivity.dateIn ? this.counterInitialAppointmentDate += 1 : ''
                            // } else {
                                // !dataClientActivity.dateAd ?
                                // x += 1
                                this.counterInitialAppointmentDate += 1
                                // dateIn
                                // : ''
                            // }
                        }
                    // }
                    // this.counterInitialAppointmentDate += dataClientActivity.dateIn
                    // else {
                        // dataClientActivity.id === '2633' ? console.log(dataClientActivity) : ''
                        // this.region ? console.log(dataClientActivity.dateAd,dataClientActivity.dateIn) : ''
                        // this.startDate && this.endDate ? console.log(dataClientActivity.id,dataClientActivity.initialAppointmentDate) : ''
                    // }

                    // Total Eligible Callers                'Closed – Received information'
                    if(dataClientActivity.statusLogical !== '' && dataClientActivity.statusLogical !== 'Closed - Received information' && dataClientActivity.statusLogical !== 'Closed – Received information' && dataClientActivity.statusLogical !== 'External Referral') {
                        // !dataClientActivity.dateIn && !dataClientActivity.dateAd ? 
                        this.counterTotalEligibleCallers += 1
                        // : ''
                    }

                    // Attended Face To Face Session
                    // if(dataClientActivity.activityStatus === 'Closed - Attended First Session' || dataClientActivity.activityStatus === 'Admitted' || dataClientActivity.activityStatus === 'Discharged') {
                    if(dataClientActivity.statusLogical === 'Closed - Attended First Session' || dataClientActivity.statusLogical === 'Admitted' || dataClientActivity.statusLogical === 'Discharged') {
                        // if(this.startDate || this.endDate || this.region || this.language || this.warmTransfer) {
                            // dataClientActivity.dateIn ? this.counterAttendedFaceToFaceSession += 1 : ''
                        // } else {
                            // !dataClientActivity.dateIn && !dataClientActivity.dateAd ? 
                            this.counterAttendedFaceToFaceSession += 1 
                            // : ''
                        // }
                    }

                    // Admitted To Treatment
                    // if(val.totalAdmission >= 1) {
                    //     this.counterAdmittedToTreatment = val.totalAdmission
                    // } else {
                        // if(dataClientActivity.statusLogical === 'Admitted' || dataClientActivity.statusLogical === 'Discharged') {
                        //     // y += 1
                        //     this.counterAdmittedToTreatment += 1
                        // }
                    // }
                    // this.counterAdmittedToTreatment += dataClientActivity.dateAd

                    // warmTransfer
                    if(dataClientActivity.warmTransfer) {
                        if(dataClientActivity.warmTransfer === 'Yes') {
                            this.counterWarmTransfer += 1
                        }
                    }

                    if(dataClientActivity.referredBy) {
                        if(dataClientActivity.referredBy === 'Existing or past CFE client') {
                            this.countExistingorpastCFEclient += 1
                        } else if(dataClientActivity.referredBy === 'Family member/Self' || dataClientActivity.referredBy === 'Family Member / Self') {
                            this.countFamilymemberSelf += 1
                        } else if(dataClientActivity.referredBy === 'GA/GamAnon' || dataClientActivity.referredBy === 'GA/ GamAnon') {
                            this.countGAGamAnon += 1
                        } else if(dataClientActivity.referredBy === 'HOPEline') {
                            this.countHOPEline += 1
                        } else if(dataClientActivity.referredBy === 'Internet Search') {
                            this.countInternetSearch += 1
                        } else if(dataClientActivity.referredBy === 'Legal System') {
                            this.countLegalSystem += 1
                        } else if(dataClientActivity.referredBy === 'Other service provider') {
                            this.countOtherserviceprovider += 1
                        } else if(dataClientActivity.referredBy === 'Other (please describe)') {
                            this.countOtherpleasedescribe += 1
                        } else if(dataClientActivity.referredBy === 'Friends or Other Individual') {
                            this.countFriendsorOtherIndividual += 1
                        } else if(dataClientActivity.referredBy === 'Problem Gambling Self-Help Group Meetings') {
                            this.countProblemGamblingSelfHelpGroupMeetings += 1
                        } else if(dataClientActivity.referredBy === 'Substance Use Self-Help Group Meetings') {
                            this.countSubstanceUseSelfHelpGroupMeetings += 1
                        } else if(dataClientActivity.referredBy === 'Financial Counseling') {
                            this.countFinancialCounseling += 1
                        } else if(dataClientActivity.referredBy === 'Crisis Services') {
                            this.countCrisisServices += 1
                        } else if(dataClientActivity.referredBy === 'Problem Gambling Treatment Services') {
                            this.countProblemGamblingTreatmentServices += 1
                        } else if(dataClientActivity.referredBy === 'Substance Use Treatment Services') {
                            this.countSubstanceUseTreatmentServices += 1
                        } else if(dataClientActivity.referredBy === 'Recovery Support Services') {
                            this.countRecoverySupportServices += 1
                        }
                    }

                    arrayExport.push({
                        id: dataClientActivity.id,
                        // clientID: dataClientActivity.clientID,
                        // language: dataClientActivity.language,
                        // clientName:,
                        status: dataClientActivity.statusLogical,
                        // statusLogical:,
                        contactDate: dataClientActivity.contactDateFormat ? moment(dataClientActivity.contactDateFormat,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // contactDateFormat:,
                        admissionDate: dataClientActivity.admissionDateFormat ? moment(dataClientActivity.admissionDateFormat,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // admissionDateFormat:,
                        dischargeDate: dataClientActivity.dischargeDateFormat ? moment(dataClientActivity.dischargeDateFormat,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        region: dataClientActivity.borough ? dataClientActivity.borough : dataClientActivity.region,
                        // insurance: dataClientActivity,

                        // new fields
                        warmTransfer: dataClientActivity.warmTransfer ? dataClientActivity.warmTransfer : '',
                        methodOfContact: dataClientActivity.methodOfContact ? dataClientActivity.methodOfContact : '',
                        contactStatus: dataClientActivity.contactStatus ? dataClientActivity.contactStatus : '',
                        // wasTheCallHelpful: dataClientActivity.wasTheCallHelpful ? dataClientActivity.wasTheCallHelpful : '',
                        readmission: dataClientActivity.readmission ? dataClientActivity.readmission : '',
                        connectedToPeerAdvocate: dataClientActivity.connectedToPeerAdvocate ? dataClientActivity.connectedToPeerAdvocate : '',
                        connectedToVSE: dataClientActivity.connectedToVSE ? dataClientActivity.connectedToVSE : '',
                        connectedToPeerEducationGroup: dataClientActivity.connectedToPeerEducationGroup ? dataClientActivity.connectedToPeerEducationGroup : '',
                        connectedToFamilyEducationGroup: dataClientActivity.connectedToFamilyEducationGroup ? dataClientActivity.connectedToFamilyEducationGroup : '',
                        connectedToAsianServicesProgram: dataClientActivity.connectedToAsianServicesProgram ? dataClientActivity.connectedToAsianServicesProgram : '',
                        informationKitAccepted: dataClientActivity.informationKitAccepted,
                        initialAppointmentDate: dataClientActivity.initialAppointmentDateFormat ? moment(dataClientActivity.initialAppointmentDateFormat,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // initialAppointmentDateFormat: dataClientActivity,
                        // activityStatus: dataClientActivity,
                        howWasClientReferredForServices: dataClientActivity.referredBy,
                        // dateIn: dataClientActivity,
                        // dateAd: dataClientActivity,
                        clientStatusAdmission: dataClientActivity.clientStatusAdmission ? dataClientActivity.clientStatusAdmission : '',
                        primaryICD10: dataClientActivity.primaryICD10 ? dataClientActivity.primaryICD10 : '',
                        locadtrgCompleted: dataClientActivity.locadtrg ? dataClientActivity.locadtrg : '',
                        LOCADTRAssessmentID: dataClientActivity.LOCADTRAssessmentID ? dataClientActivity.LOCADTRAssessmentID : '',

                        // client information
                        clientID: dataClientActivity.clientID ? dataClientActivity.clientID : '',
                        insurance: dataClientActivity.clientInsurance ? dataClientActivity.clientInsurance : '',
                        county: dataClientActivity.county ? dataClientActivity.county : '',
                        language: dataClientActivity.language ? dataClientActivity.language : '',
                        // gender: dataClientActivity.gender ? dataClientActivity.gender : '',
                        program: dataClientActivity.clinicianProgram ? dataClientActivity.clinicianProgram : ''
                    })
                }
            })
            this.isBusy = false
            
            this.json_data = arrayExport
            this.readyToExport = true
        }
    },
    methods: {
        ...mapActions(['searchClientActivityName','searchClientActivityStatus','searchClientActivityClinicianName','searchReportsCallDataRegion','searchReportsCallDataProgress','searchReportsCallDataLanguage','searchReportsCallDataInsurance','searchReportsCallDataWarmTransfer','searchClientActivityClinicianStatus','searchClientActivityClientID','searchReportsCallDataStartDate','searchReportsCallDataEndDate','setSnapshotClientActivityReportsCallData','searchReportsCallDataProgram']),
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.region = ''
            this.borough = ''
            this.language = ''
            this.progress = ''
            this.insurance = ''
            this.warmTransfer = ''
            this.clinicianProgram = null
            // this.functionFilters()
            this.activeFilters = false
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        functionFilters() {
            if(this.clientActivityStatus) {
                this.searchClientActivityStatus(this.clientActivityStatus)
            } else {
                this.searchClientActivityStatus('')
            }

            if(this.startDate) {
                this.searchReportsCallDataStartDate(this.startDate)
            } else {
                this.searchReportsCallDataStartDate('')
            }

            if(this.endDate) {
                this.searchReportsCallDataEndDate(this.endDate)
            } else {
                this.searchReportsCallDataEndDate('')
            }

            if(this.region) {
                this.searchReportsCallDataRegion(this.region)
            } else {
                this.searchReportsCallDataRegion('')
            }

            if(this.progress) {
                this.searchReportsCallDataProgress(this.progress)
            } else {
                this.searchReportsCallDataProgress('')
            }

            if(this.language) {
                this.searchReportsCallDataLanguage(this.language)
            } else {
                this.searchReportsCallDataLanguage('')
            }

            if(this.insurance) {
                this.searchReportsCallDataInsurance(this.insurance)
            } else {
                this.searchReportsCallDataInsurance('')
            }

            if(this.warmTransfer) {
                this.searchReportsCallDataWarmTransfer(this.warmTransfer)
            } else {
                this.searchReportsCallDataWarmTransfer('')
            }
        }
    },
    created() {
        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/calldata-reports')
        .then(res => {
            // console.log(res.data)
            // res.data.forEach(ele => {
                this.engagementFormCompleted = res.data.counter.engagementFormCompleted
                this.counterInformationKitAccepted = res.data.counter.counterInformationKitAccepted
                this.counterInitialAppointmentDate = res.data.counter.counterInitialAppointmentDate
                this.counterTotalEligibleCallers = res.data.counter.counterTotalEligibleCallers
                this.counterAttendedFaceToFaceSession = res.data.counter.counterAttendedFaceToFaceSession
                this.counterAdmittedToTreatment = res.data.counter.counterAdmittedToTreatment
                this.countExistingorpastCFEclient = res.data.counter.countExistingorpastCFEclient
                this.countFamilymemberSelf = res.data.counter.countFamilymemberSelf
                this.countGAGamAnon = res.data.counter.countGAGamAnon
                this.countHOPEline = res.data.counter.countHOPEline
                this.countInternetSearch = res.data.counter.countInternetSearch
                this.countLegalSystem = res.data.counter.countLegalSystem
                this.countOtherserviceprovider = res.data.counter.countOtherserviceprovider
                this.countOtherpleasedescribe = res.data.counter.countOtherpleasedescribe

                this.setSnapshotClientActivityReportsCallData(res.data.activity)
                this.isBusy = false
                this.isBusyCalls = false
            // })
        })

        // filters
        this.startDate = this.searchReportsCallDataStartDateState ? this.searchReportsCallDataStartDateState : null
        this.endDate = this.searchReportsCallDataEndDateState ? this.searchReportsCallDataEndDateState : null
        this.region = this.searchReportsCallDataRegionState ? this.searchReportsCallDataRegionState : ''
        this.progress = this.searchReportsCallDataProgressState ? this.searchReportsCallDataProgressState : ''
        this.language = this.searchReportsCallDataLanguageState ? this.searchReportsCallDataLanguageState : ''
        this.insurance = this.searchReportsCallDataInsuranceState ? this.searchReportsCallDataInsuranceState : ''
        this.warmTransfer = this.searchReportsCallDataWarmTransferState ? this.searchReportsCallDataWarmTransferState : ''
        this.clinicianProgram = this.searchReportsCallDataProgramState ? (this.searchReportsCallDataProgramState === 'oasas' ? 'OASAS' : this.searchReportsCallDataProgramState === 'pgrc - private practitioner' ? 'PGRC - Private Practitioner' : this.searchReportsCallDataProgramState === 'pgrc - group practice' ? 'PGRC - Group Practice' : this.searchReportsCallDataProgramState === 'pgrc - oasas program' ? 'PGRC - OASAS Program' : null) : null

        db.collection('clients').get()
        .then(docsClients => {
            this.totalNonEnglishClients = 0
            this.clientSatisfaction = 0
            docsClients.forEach(itemClient => {
                if(itemClient.data().status === 'active') {
                    var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
                    if(!languageLowerCase.includes("english")) {
                        this.totalNonEnglishClients += 1
                    }
                }
            })
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            // profile fields
            // this.optionsRegion = docTaxonomia.data().region
            this.optionsLanguage = docTaxonomia.data().primaryLanguage
            this.optionsInsurance = docTaxonomia.data().insuranceType
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                arrayProgram.push({value: item, text: item})
            })
            this.optionsProgram = arrayProgram
        })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
.hidden_header {
    display: none;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>